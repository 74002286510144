define("discourse/plugins/discourse-locations/discourse/templates/components/location-label-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="location-label" title={{i18n "location.label.title"}}>
    {{d-icon "map-marker-alt"}}
    <span class="location-text">
      {{location-format this.topic.location this.opts}}
    </span>
  </div>
  
  {{#if this.showMapToggle}}
    <div class="location-topic-map">
      <button
        onclick={{action "showMap"}}
        class="btn btn-small btn-default"
        type="button"
      >
        {{d-icon "far-map"}}
        {{#unless this.site.mobileView}}
          {{i18n "location.geo.show_map"}}
        {{/unless}}
      </button>
    </div>
    {{#if this.showMap}}
      <MapComponent @topic={{this.topic}} @size="small" />
    {{/if}}
  {{/if}}
  */
  {
    "id": "rRrcCBCy",
    "block": "[[[10,0],[14,0,\"location-label\"],[15,\"title\",[28,[37,0],[\"location.label.title\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"map-marker-alt\"],null]],[1,\"\\n  \"],[10,1],[14,0,\"location-text\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"topic\",\"location\"]],[30,0,[\"opts\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showMapToggle\"]],[[[1,\"  \"],[10,0],[14,0,\"location-topic-map\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,4],[[30,0],\"showMap\"],null]],[14,0,\"btn btn-small btn-default\"],[14,4,\"button\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"far-map\"],null]],[1,\"\\n\"],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"        \"],[1,[28,[35,0],[\"location.geo.show_map\"],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@topic\",\"@size\"],[[30,0,[\"topic\"]],\"small\"]],null],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"i18n\",\"d-icon\",\"location-format\",\"if\",\"action\",\"unless\",\"map-component\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/templates/components/location-label-container.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-locations/discourse/widgets/user-location", ["exports", "discourse/widgets/widget", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse-common/lib/icon-library", "virtual-dom", "I18n"], function (_exports, _widget, _locationUtilities, _iconLibrary, _virtualDom, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("user-location", {
    tagName: "div.user-location-widget",
    buildKey: () => "user-location",
    defaultState() {
      return {
        showMap: false
      };
    },
    html(attrs, state) {
      const {
        user
      } = attrs;
      let contents = [];
      if (user && user.geo_location) {
        contents.push((0, _iconLibrary.iconNode)("map-marker-alt"));
        let format = this.siteSettings.location_user_profile_format.split("|");
        let opts = {};
        let userLocation;
        if (format.length && format[0]) {
          opts["geoAttrs"] = format;
          userLocation = (0, _locationUtilities.geoLocationFormat)(user.geo_location, this.site.country_codes, opts);
        } else {
          userLocation = user.geo_location.address;
        }
        contents.push((0, _virtualDom.h)("div.location-label", userLocation));
        if (this.siteSettings.location_user_profile_map) {
          let mapContents = [];
          let btnParams = {
            icon: "far-map",
            className: "btn-default btn-show-map btn-small",
            action: "toggleMap"
          };
          if (!this.site.mobileView && attrs.formFactor !== "card") {
            btnParams.contents = _I18n.default.t(`location.geo.${state.showMap ? "hide" : "show"}_map`);
          }
          mapContents.push(this.attach("button", btnParams));
          if (state.showMap) {
            mapContents.push((0, _virtualDom.h)("div.map-container.small", this.attach("map", {
              user,
              disableExpand: attrs.formFactor === "card"
            })));
          }
          contents.push((0, _virtualDom.h)("div.map-wrapper", mapContents));
        }
      }
      return contents;
    },
    toggleMap() {
      this.state.showMap = !this.state.showMap;
    }
  });
});
define("discourse/plugins/discourse-locations/discourse/components/map-component", ["exports", "discourse/components/mount-widget", "discourse-common/utils/decorators", "@ember/runloop"], function (_exports, _mountWidget, _decorators, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _mountWidget.default.extend((_dec = (0, _decorators.on)("didInsertElement"), _dec2 = (0, _decorators.observes)("topicList.[]", "category", "geoLocation", "geoLocations.[]", "userList.[]"), (_obj = {
    classNameBindings: [":map-component", ":map-container", "size"],
    widget: "map",
    clickable: false,
    buildArgs() {
      let args = this.getProperties("category", "topic", "user", "locations", "clickable", "topicList", "userList", "search", "showAvatar", "size", "center", "zoom");
      if (this.get("geoLocation")) {
        if (!args["locations"]) {
          args["locations"] = [];
        }
        args["locations"].push({
          geo_location: this.get("geoLocation")
        });
      }
      return args;
    },
    setupOnRender() {
      this.scheduleSetup();
    },
    refreshMap() {
      this.queueRerender();
      this.scheduleSetup();
    },
    scheduleSetup() {
      (0, _runloop.scheduleOnce)("afterRender", () => this.appEvents.trigger("dom:clean"));
      (0, _runloop.later)(() => this.appEvents.trigger("sidebars:after-render"));
    }
  }, (_applyDecoratedDescriptor(_obj, "setupOnRender", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupOnRender"), _obj), _applyDecoratedDescriptor(_obj, "refreshMap", [_dec2], Object.getOwnPropertyDescriptor(_obj, "refreshMap"), _obj)), _obj)));
});